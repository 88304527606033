<template>
    <div>
        <landing-page-header></landing-page-header>
        <main>
            <!-- <div>
                <img src="../../assets/home/track_order.jpg" alt="" style="width:100%; object-fit:cover;">
            </div> -->
            <div class="tracking-area container-fluid">
                <div class="tracking-area-header text-center mb-5">
                    <h3>Subscription plans</h3>
                </div>
                <div id="pricingWrapper" class="row">
                    <div class="col-md-6 col-lg-4" v-for="(p, index) in packages" :key="index">
                        <div class="card stacked mt-5">
                            <div class="card-header pt-0">
                                <!-- <span class="card-price">{{ formatNumberToCurrency(p.subscription_amount) }}</span>
                                <h3 class="card-title mt-3 mb-1">{{ p.name }}</h3> -->
                                <span class="card-price">{{ p.name }}</span>
                                <h3 class="card-title mt-3 mb-1">{{ formatNumberToCurrency(p.subscription_amount) }}</h3>
                                <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p> -->
                            </div>
                            <div class="card-body">
                                <div v-html="p.features"></div>
                                <!-- <ul class="list-group list-group-minimal mb-3">
                                    <li class="list-group-item d-flex justify-content-between align-items-center">N950 per delivery within Lagos and Abuja
                                    </li>
                                    <li class="list-group-item d-flex justify-content-between align-items-center">105 Pickups and Deliveries
                                    </li>
                                    <li class="list-group-item d-flex justify-content-between align-items-center">60 Days validity period
                                    </li>
                                    <li class="list-group-item d-flex justify-content-between align-items-center">Dedicated account manager
                                    </li>
                                    <li class="list-group-item d-flex justify-content-between align-items-center">Multiple pickup locations
                                    </li>
                                </ul> -->
                                <router-link to="/packages" class="btn btn-block btn-primary">Buy Now</router-link>
                            </div>
                        </div>
                    </div>                              
                </div>
            </div>
            
        </main>
        <landing-page-footer></landing-page-footer>
    </div>
</template>
<script>
import header from "./layout/header.vue"
import footer from "./layout/footer.vue"
import instance from '../../presenter/api/network'
export default {
    components: { LandingPageHeader: header, LandingPageFooter: footer },
    data(){
        return {
            packages: [],
            packagesNotFound: false,
            loading: false,
            
        }
    },
    methods: {
        getPackages(){
            instance.get("subscriptionList").then(res => {
                let responseData = res.data.data;
                this.packages = responseData;
            })
        },
    },
    mounted(){
        this.getPackages();
    }
    
}
</script>
<style scoped>
main{
    
}

.tracking-area{
    padding-top: 80px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 80px;
    background: white;
}

.tracking-area h3{
    font-size: 2.5rem;
    font-weight: bolder;
    line-height: 1.2;
}

 .track {
     position: relative;
     background-color: #ddd;
     height: 7px;
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     margin-bottom: 60px;
     margin-top: 50px
 }

 .track .step {
     -webkit-box-flex: 1;
     -ms-flex-positive: 1;
     flex-grow: 1;
     width: 25%;
     margin-top: -18px;
     text-align: center;
     position: relative
 }

 .track .step.active:before {
     background: #e2591d;
 }

 .track .step::before {
     height: 7px;
     position: absolute;
     content: "";
     width: 100%;
     left: 0;
     top: 18px
 }

 .track .step.active .icon {
     background: #ee5435;
     color: #fff
 }

 .track .icon {
     display: inline-block;
     width: 40px;
     height: 40px;
     line-height: 40px;
     position: relative;
     border-radius: 100%;
     background: #ddd
 }

 .track .step.active .text {
     font-weight: 400;
     color: #000
 }

 .track .text {
     display: block;
     margin-top: 7px
 }

@media only screen and (max-width: 768px){
    .track{
        flex-direction: column;
        height: auto;
        background-color: white;
        margin-top:80px;
    }
    .track .step {
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        /* width: initial; */
        margin-top: 0;
        text-align: center;
        position: relative
    }
    .track .step::before {
        height: 100%;
        position: absolute;
        content: "";
        width: 7px;
        left: 36px;
        top: -18px
    }
    .track .text{
        width: 100%;
        margin-left: 60px;
    }
    
}
</style>